import axios from 'axios';

export const fetchRegions = (limit: number | null, skip: number, search?: string) =>
  axios({
    method: 'POST',
    url: 'admin-service/country/list',
    data: {
      searchTerm: search || '',
      skip: skip || '',
      limit: limit || ''
    }
  });

export const getFileURL = () =>
  axios({
    method: 'POST',
    url: '/get-dump-url'
  });

export const getSpiceDashboardURL = (data: { username: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/region/spice-dashboard-url',
    data
  });

export const getTcDashboardURL = (data: { username: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/region/tc-dashboard-url',
    data
  });
