import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as userActions from './actions';
import * as USERTYPES from './actionTypes';
import { IFetchLoggedInUserRequest, IUser } from './types';
import * as userService from '../../services/userAPI';
import * as regionService from '../../services/regionAPI';
import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import { info } from '../../utils/toastCenter';
import { routeToParentApp } from '../../utils/routeUtils';

export function* logout(): SagaIterator {
  try {
    yield call(userService.logout);
    sessionStorageServices.clearAllItem();
    yield put(userActions.logoutSuccess());
    yield put(userActions.resetStore());
    routeToParentApp(true);
  } catch (e) {
    sessionStorageServices.deleteItem(APPCONSTANTS.AUTHTOKEN);
    yield put(userActions.logoutFailure());
  }
}

export function* fetchTimezoneList(): SagaIterator {
  try {
    const {
      data: { data: timezoneList }
    } = yield call(userService.fetchTimezoneList);
    yield put(userActions.fetchTimezoneListSuccess(timezoneList));
  } catch (e) {
    yield put(userActions.fetchTimezoneListFailure());
  }
}

export function* fetchLoggedInUser(action: IFetchLoggedInUserRequest): SagaIterator {
  try {
    let {
      data: {
        entity: {
          username: email,
          firstName,
          lastName,
          id: userId,
          roles,
          tenantId,
          country,
          organizations,
          suiteAccess
        }
      }
    } = yield call(userService.fetchLoggedInUser);
    // The below snippets will be removed once we implemented common dasboard UI
    if (!country) {
      const {
        data: { entityList: regions}
      } = yield call(regionService.fetchRegions as any, 10, 0, '');
      country = regions.filter((region: any) => region.appTypes.length === 1 && region.appTypes.includes('COMMUNITY'))[0];
    }
    sessionStorageServices.setItem(APPCONSTANTS.USER_TENANTID, tenantId);
    sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_TENANT_ID, country?.tenantId);

    const filteredRoles = roles.filter((role: any) => Object.values(APPCONSTANTS.ROLES).includes(role.name));
    if (!filteredRoles.length) {
      info(APPCONSTANTS.ALERT, APPCONSTANTS.UNAUTHORIZED_ACCESS_MESSAGE);
      yield put(userActions.logoutRequest());
    }
    const payload: IUser = {
      email,
      firstName,
      lastName,
      userId,
      role: filteredRoles[0].name,
      roleDetail: filteredRoles[0],
      tenantId,
      country,
      suiteAccess,
      formDataId: organizations[0]?.formDataId,
      assignedFacility: organizations[0] || {}
    };
    yield put(userActions.fetchLoggedInUserSuccess(payload));
  } catch (e) {
    yield put(userActions.fetchLoggedInUserFail());
  }
}

export function* fetchDefaultRoles(): SagaIterator {
  try {
    const { data: roles } = yield call(userService.getDefaultRoles);
    yield put(userActions.fetchDefaultRoleSuccess(roles));
  } catch (e) {
    yield put(userActions.fetchDefaultRoleFailure());
  }
}

/*
  Starts worker saga on latest dispatched `LOGIN_REQUEST` action.
  Allows concurrent increments.
*/
function* userSaga() {
  yield all([takeLatest(USERTYPES.LOGOUT_REQUEST, logout)]);
  yield all([takeLatest(USERTYPES.FETCH_TIMEZONE_LIST_REQUEST, fetchTimezoneList)]);
  yield takeLatest(USERTYPES.FETCH_LOGGED_IN_USER_REQUEST, fetchLoggedInUser);
  yield all([takeLatest(USERTYPES.FETCH_DEFAULT_ROLE, fetchDefaultRoles)]);
}

export default userSaga;
